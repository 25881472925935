import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Coman/Sidebar";
import Header from "../Coman/Header";
import { ProjectContext } from "../../ProjectContext";
import axios from 'axios';

export default function PurchaseHistry() {
  const { account, copyaddress, formatAddress } = useContext(ProjectContext)
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "transaction",
        submethod: "getbyid",
        // address: "default",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);


  return (
    <>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper">
                <Sidebar />
                <Header />
                <div className="main-chat bg-dash-img px-md-5 px-1">
                  <div className="px-md-5 px-1 mt-0 mt-md-5">
                    <div style={{ overflow: "auto hidden" }}>
                      <h2 className="text-white mb-5 text-center mt-3">
                        Purchase History
                      </h2>
                      <div className="table-main">
                        <div className="tbl-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-black"> #</th>
                                <th className="text-black">
                                  Amount
                                </th>
                                <th className="text-black">Bot</th>
                                <th className="text-black">Datetime</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="tbl-content">
                          <tbody>
                            {data.map((ele, i) => {
                              return (
                                <tr>
                                  <th> {ele.id}</th>
                                  <th>$ {ele.amount} </th>
                                  <th> {ele.amount === 25 ? "BEGINNER BOT" : ele.amount === 100 ? "INTERMEDIATE BOT" : ele.amount === 500 ? "PROFESSIONAL BOT" : "Invalid Bot"} </th>
                                  <th>{ele.createdAt}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tbody />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <nav aria-label="Page navigation example">
                    <ul className="pagination  mt-5 justify-content-center">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link "
                          href="#"
                          aria-label="Next"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
