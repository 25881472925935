import React, { useContext } from "react";
import CountdownTimer from "../Coman/CountdownTimer";
import { ProjectContext } from "../../ProjectContext";

export default function Body() {
  const {
    account,
    dbuser,
    copyaddress,
    formatAddress,
  } = useContext(ProjectContext);
  return (
    <div>
      <div>
        {/* <h2 className="text-white mb-5 text-center mt-3">Purchase Bot </h2> */}
        <div className="contact-details">
          <div className="col-lg-12 mt-3"></div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div class="step-1 py-5">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  class="step-text text-white  mt-3"
                  style={{ position: "relative", zIndex: "999" }}
                >
                  <h5 onClick={() => copyaddress(account)}>
                    My Account : {formatAddress(account)}
                    <i
                      class="fa-solid fa-copy ms-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </h5>
                  <h5 onClick={() => copyaddress(dbuser ? dbuser.ref_address : '')}>
                    Referral Address : {dbuser !== null ? formatAddress(dbuser.ref_address) : "-"}{" "}
                    <i
                      class="fa-solid fa-copy ms-2 "
                      style={{ cursor: "pointer" }}
                    ></i>
                  </h5>
                </div>
                <div
                  class="step-text text-white mt-3"
                  style={{ position: "relative", zIndex: "999" }}
                >
                  <h5 onClick={() => copyaddress(`${process.env.REACT_APP_LINK}purchase-bot?referral=${account}`)}>
                    Referral Link : Click to copy{" "} <i
                      class="fa-solid fa-copy ms-2 "
                      style={{ cursor: "pointer" }}
                    ></i>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-white   mt-5">Rewards</h3>
          <div className="row ">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Sponsor Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">
                  Mobilization Rewards
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Salary Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Activity Rewards</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Royalty Rewards</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-white   mt-5">Statistics</h3>
          <div className="row ">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">${dbuser ? dbuser.t_staking : '0'}</div>
                <div class="step-text text-white mt-3">Bot</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Liquidity</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Capability</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Team</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Direct Team</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">
                  <CountdownTimer targetDate={"2024-10-15T15:00:00"} />
                </div>
                <div class="step-text text-white mt-3">Starts</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Next Club</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Team Business</div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">One Sales Club </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">One Sales Team </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Pending Withdrawal </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3">
              <div class="step-1 py-4">
                <div class="step-number-1">$0</div>
                <div class="step-text text-white mt-3">Total Withdrawal </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
