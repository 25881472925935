import React from "react";

export default function Liquidity() {
  return (
    <div>
      <div>
        <div className="row justify-content-center mt-0 mt-md-5">
          <div className="col-lg-7  col-md-10 col-sm-12 col-12 mt-3">
            <h2 className="text-white mb-5 text-center">Add Liquidity </h2>

            <div class="step-1 py-4">
              <div
                class="step-text text-white mt-3"
                style={{ position: "relative", zIndex: "999" }}
              >
                <form className="auth-form mx-4">
                  <div className="mb-3 form-group text-start">
                    <i class="iconsax" data-icon="mail">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </i>
                    <label htmlFor="password" className="form-label">
                      Enter Amount
                    </label>

                    <input
                      type="password"
                      placeholder="$ "
                      class="form-control"
                      id="password"
                    />
                  </div>
                  <div className="mb-3 form-group text-start">
                    <i class="iconsax" data-icon="lock-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
                          stroke="#292D32"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </i>
                    <label htmlFor="email" className="form-label">
                      Email ID
                    </label>

                    <input
                      type="text"
                      placeholder="Enter your mail id"
                      class="form-control"
                      id="email"
                    />
                  </div>
                  <form className="d-flex align-items-center justify-content-center gap-5">
                    <div>
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        defaultValue="HTML"
                      />
                      &nbsp; <label htmlFor="html">Ton (BEP20) </label>
                      <br />
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        defaultValue="CSS"
                      />
                      &nbsp; <label htmlFor="css">USDT</label>
                      <br />
                    </div>
                  </form>

                  {/* <div className="d-flex justify-content-between mx-3">
                        <h6>State</h6>
                        <h6>$100</h6>
                      </div> */}
                  {/* <div className="d-flex justify-content-between mx-3">
                    <h6>State</h6>
                    <h6>$100</h6>
                  </div> */}
                  <button class="btn-solid w-100 text-center mt-5">
                    Add Liquidity
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
