import React from "react";
import Sidebar from "../../Coman/Sidebar";
import Header from "../../Coman/Header";

export default function Team() {
  return (
    <div>
      <div>
        <section className="chatting-wrapper pt-0 text-start">
          {/* <Header/> */}
          <div className="tab-content">
            <div className="tab-pane fade show active">
              <div className="main-wrapper">
                <Sidebar />
                <Header />
                <div className="main-chat bg-dash-img px-md-5 px-1">
                  <div className="px-md-5 px-1 mt-0 mt-md-5">
                    <div style={{ overflow: "auto hidden" }}>
                      <h2 className="text-white mb-5 text-center mt-3">Team</h2>
                      <form className="auth-form d-flex justify-content-end mb-3">
                       <div>
                       <div className="form-group">
                          <i className="iconsax" data-icon="search-normal-2">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                                fill="#292D32"
                              />
                              <path
                                d="M21.9995 22.7514C21.8095 22.7514 21.6195 22.6814 21.4695 22.5314L19.4695 20.5314C19.1795 20.2414 19.1795 19.7614 19.4695 19.4714C19.7595 19.1814 20.2395 19.1814 20.5295 19.4714L22.5295 21.4714C22.8195 21.7614 22.8195 22.2414 22.5295 22.5314C22.3795 22.6814 22.1895 22.7514 21.9995 22.7514Z"
                                fill="#292D32"
                              />
                            </svg>
                          </i>
                          <input
                            type="search"
                            className="form-control"
                            placeholder="Search here"
                          />
                        </div>
                       </div>
                      </form>

                      <div className="table-main">
                        <div className="tbl-header">
                          <table>
                            <thead>
                              <tr>
                                <th className="text-black"> Sr.</th>
                                <th className="text-black">
                                  Reward Eligibility
                                </th>
                                <th className="text-black">Stake($)</th>
                                <th className="text-black">Rate</th>
                                <th className="text-black">Datetime</th>
                                <th className="text-black">Status</th>
                              </tr>
                            </thead>
                          </table>
                        </div>
                        <div className="tbl-content">
                          <tbody>
                            <tr>
                              <th> 1</th>
                              <th>Reward </th>
                              <th>454</th>
                              <th>22</th>
                              <th>200</th>
                              <th>00</th>
                            </tr>
                            <tr>
                              <th> 1</th>
                              <th>Reward </th>
                              <th>454</th>
                              <th>22</th>
                              <th>200</th>
                              <th>00</th>
                            </tr>
                            <tr>
                              <th> 1</th>
                              <th>Reward </th>
                              <th>454</th>
                              <th>22</th>
                              <th>200</th>
                              <th>00</th>
                            </tr>
                            <tr>
                              <th> 1</th>
                              <th>Reward </th>
                              <th>454</th>
                              <th>22</th>
                              <th>200</th>
                              <th>00</th>
                            </tr>
                          </tbody>
                          <tbody />
                        </div>
                      </div>
                    </div>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination  mt-5 justify-content-center">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link "
                          href="#"
                          aria-label="Next"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav>

                  <div className="msger-chat"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
