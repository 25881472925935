import React from 'react'
import Sidebar from '../Coman/Sidebar'
import Header from '../Coman/Header'

export default function RoyaltyRewards() {
  return (
    <>
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img px-md-5 px-1">
                <div className="px-md-5 px-1 mt-0 mt-md-5">
                  <div style={{ overflow: "auto hidden" }}>
                    <h2 className="text-white mb-5 text-center mt-3">
                    Royalty Rewards
                    </h2>
                    <div className="table-main">
                      <div className="tbl-header">
                        <table>
                          <thead>
                            <tr>
                              <th className="text-black"> Sr.</th>
                              <th className="text-black">
                                Reward Eligibility
                              </th>
                              <th className="text-black">Stake($)</th>
                              <th className="text-black">Rate</th>
                              <th className="text-black">Datetime</th>
                              <th className="text-black">Status</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <div className="tbl-content">
                        <tbody>
                          <tr>
                            <th> 1</th>
                            <th>Reward </th>
                            <th>454</th>
                            <th>22</th>
                            <th>200</th>
                            <th>00</th>
                          </tr>
                          <tr>
                            <th> 1</th>
                            <th>Reward </th>
                            <th>454</th>
                            <th>22</th>
                            <th>200</th>
                            <th>00</th>
                          </tr>
                          <tr>
                            <th> 1</th>
                            <th>Reward </th>
                            <th>454</th>
                            <th>22</th>
                            <th>200</th>
                            <th>00</th>
                          </tr>
                          <tr>
                            <th> 1</th>
                            <th>Reward </th>
                            <th>454</th>
                            <th>22</th>
                            <th>200</th>
                            <th>00</th>
                          </tr>
                        </tbody>
                        <tbody />
                      </div>
                    </div>
                  </div>
                </div>
                <nav aria-label="Page navigation example">
                    <ul className="pagination  mt-5 justify-content-center">
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Previous"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">«</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link text-white bg-black" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link "
                          href="#"
                          aria-label="Next"
                          style={{ background: "#00ffa3" }}
                        >
                          <span aria-hidden="true">»</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                <div className="msger-chat"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
  )
}
