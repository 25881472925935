import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Coman/Sidebar";
import Header from "../../Coman/Header";
import { useLocation } from "react-router-dom";
import { ProjectContext } from "../../../ProjectContext";
import axios from "axios";

export default function PurchaseBot() {
  const {
    account,
    dbuser,
    connectMetamask,
    getudata,
    settings,
    esigner,
    ethers,
    stakcontract,
    getsmcs,
    copyaddress,
    getuserDB,
    formatAddress,
    balance,
    tokenrate,
    toastSuccess,
    toastError,
    smuser,
  } = useContext(ProjectContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const link_ref_address = searchParams.get('referral');

  const [referral, setreferral] = useState("");
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const onDeposit = async (amount, pkg) => {
    settrdone(true);

    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (Number(amount) > balance) {
      toastError("Insufficient Balance");
      settrdone(false);
      // return;
    }
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }

    var data = await getudata(referral);
    if (data.data.error) {
      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    let token_contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );
    settrdone(true);
    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await ethers.eth.getGasPrice()
      //   );

      try {
        var stk = await stakcontract.address_whitelist(
          referral,
          pkg
        );
        const receipt = await stk.wait();
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');
        sethash(receipt.transactionHash);
        // sethash(randomHash);
        getsmcs();
        settrdone(false);
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            ref_address: referral,
            hash: receipt.transactionHash,
            // hash: randomHash,
            amount: amount,
            token: total_tokens,
            rate: tokenrate,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            getuserDB();
            toastSuccess("Transaction done");
          });
      } catch (error) {
        console.log(error);
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
    }

    if (Number(ethers.utils.formatEther(allowance)) < amount) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.ref_address !== null) {
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);

    }
  }, [link_ref_address, dbuser, account]);
  // useEffect(() => {
  //   settotal_tokens(amount / tokenrate);
  // }, [amount]);


  return (
    <div>
      <section className="chatting-wrapper pt-0 text-start">
        {/* <Header/> */}
        <div className="tab-content">
          <div className="tab-pane fade show active">
            <div className="main-wrapper">
              <Sidebar />
              <Header />
              <div className="main-chat bg-dash-img px-md-5 px-1">
                <div className="px-md-5 px-1 mt-0 mt-md-5">
                  <h2 className="text-white mb-5 text-center mt-3">Purchase Bot </h2>
                  <div class="step-1 py-4">
                    <div
                      class="step-text text-white mt-3"
                      style={{ position: "relative", zIndex: "999" }}
                    >
                      <form className="auth-form mx-4">
                        <div className="mb-3 form-group text-start">

                          <label htmlFor="password" className="form-label">
                            Referral Address
                          </label>

                          {dbuser !== null ? (
                            dbuser.referral !== null ? (
                              <input
                                className="form-control ps-2"
                                type="text"
                                value={referral}
                              />
                            ) : (
                              <input
                                className="form-control ps-2"
                                type="text"
                                placeholder="Enter Referral Address"
                                value={referral}
                                onChange={(e) =>
                                  setreferral(e.target.value)
                                }
                              />
                            )
                          ) : (
                            <input
                              className="form-control ps-2"
                              type="text"
                              placeholder="Enter Referral Address"
                              value={referral}
                              onChange={(e) =>
                                setreferral(e.target.value)
                              }
                            />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {trdone ?
                      <img src="/assets/images/loader.gif" alt="loader" style={{ "width": "110px" }} /> :
                      <>
                        <div className="col-12"></div>
                        <div className="col-lg-6  col-md-6 col-sm-6 col-12 mt-3">
                          <div class="step-1 py-4" type="button" onClick={() => onDeposit(25, '0')}>
                            <div class="step-number-1">$25</div>
                            <div class="step-text text-white mt-3">BEGINNER BOT</div>
                          </div>
                        </div>
                        <div className="col-lg-6  col-md-6 col-sm-6 col-12 mt-3">
                          <div class="step-1 py-4" type="button" onClick={() => onDeposit(100, '1')}>
                            <div class="step-number-1">$100</div>
                            <div class="step-text text-white mt-3">INTERMEDIATE BOT</div>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <div class="step-1 py-4" type="button" onClick={() => onDeposit(500, '2')}>
                            <div class="step-number-1">$500</div>
                            <div class="step-text text-white mt-3">PROFESSIONAL BOT</div>
                          </div>
                        </div>
                      </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
