import React, { useContext } from "react";
import { ProjectContext } from "../../ProjectContext";
import { Link } from "react-router-dom";

export default function Header2() {
  const { connectMetamask, account } = useContext(ProjectContext);

  return (
    <div>
      <header>

        <a href="/">
          <img
            src="assets/images/img/logo.svg"

            style={{ width: "200px" }}
            className="img-fluid"
            alt="logo"
          />
        </a>
        <nav className="header-nav-middle">
          <div className="main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
            <div
              className="offcanvas offcanvas-collapse order-xl-2"
              id="primaryMenu"
            >
              <div className="offcanvas-header navbar-shadow">
                <h5 className="mb-0">Back</h5>
                <button
                  className="btn-close lead"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>

            </div>
          </div>
        </nav>
        {account ?
        <Link
          data-cursor="pointer"
          to="/dashboard"
          className="btn btn-theme "
        >
          <span>Dashboard</span>
        </Link>:
        <a
          data-cursor="pointer"
          href="#"
          className="btn btn-theme "
          onClick={() => connectMetamask()}
        >
          <span>Connect Wallet</span>
        </a>}
      </header>
    </div>
  );
}
