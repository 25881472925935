import React from "react";

export default function Header() {
  return (
    <div>
      <div className="chat-header ">
        <div className="d-flex align-items-center gap-2 " >
          <button
            className="navbar-toggler d-lg-none d-block menu-btn-color"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainnavbarNav"
            aria-controls="mainnavbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="iconsax" data-icon="text-align-justify" />
            <i class="fa fa-bars" aria-hidden="true"></i>

          </button>
          <a href="#" className="d-block d-lg-none">
            <img
                  src="assets/images/img/logo.svg"
                  style={{width:"90px"}}
              className="img-fluid"
            />
          </a>
          <h3 id="targetDiv" className="ms-md-5 ms-0"></h3>
        </div>
        <div className="header-option">
          
          <a
            className="premium-btn me-md-5 me-0"
            id="subscriptionBtn"
            data-cursor="pointer"
            href="#"
          >
            <i className="iconsax" data-icon="crown-2" /> 
            <span>Logout
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
